import { Notice } from 'view-design';
import axios from '@/plugins/axios';

class DataTransferUtil {
  /**
   * 转换枚举值为选项
   * @param textEnum - 标签枚举
   * @param options - 自定义设置
   * @param {string} options.labelFieldName - 标签字段名
   * @param {string} options.valueFieldName - 值字段名
   * @return {{[options.labelFieldName]: string, [options.valueFieldName]: string}[]}
   */
  static enumTransfer2Options(
    textEnum,
    options = { labelFieldName: 'label', valueFieldName: 'value' },
  ) {
    const {
      labelFieldName,
      valueFieldName,
    } = options;
    const resultOptions = [];

    Object.keys(textEnum)
      .forEach((key) => {
        resultOptions.push({
          [labelFieldName]: textEnum[key],
          // eslint-disable-next-line no-restricted-globals
          [valueFieldName]: isNaN(key) ? key : Number(key),
        });
      });

    return resultOptions;
  }

  static strEnumTransfer2Options(
    textEnum,
    options = { labelFieldName: 'label', valueFieldName: 'value' },
  ) {
    const {
      labelFieldName,
      valueFieldName,
    } = options;
    const resultOptions = [];

    Object.keys(textEnum)
      .forEach((key) => {
        resultOptions.push({
          [labelFieldName]: textEnum[key],
          // eslint-disable-next-line no-restricted-globals
          [valueFieldName]: key,
        });
      });

    return resultOptions;
  }

  /**
   * 转换源数据为选项
   * @param origin
   * @param originOptions
   * @param resultOptions
   * @returns {*[]}
   */
  static originTransfer2Options(
    origin = [],
    originOptions = { labelFieldName: 'name', valueFieldName: 'id' },
    resultOptions = { labelFieldName: 'label', valueFieldName: 'value' },
  ) {
    const {
      labelFieldName,
      valueFieldName,
    } = originOptions;
    const {
      labelFieldName: resultLabelFieldName,
      valueFieldName: resultValueFieldName,
    } = resultOptions;
    const result = [];

    origin.forEach((item) => {
      result.push({
        [resultLabelFieldName]: item[labelFieldName],
        [resultValueFieldName]: item[valueFieldName],
      });
    });

    return result;
  }

  static async downloadFileBySecretKey(secretKey = '', fileName = '') {
    Notice.info({
      title: '正在导出文件',
    });

    const result = await axios({
      url: '/api/file/export/download',
      data: {
        secret_key: secretKey,
      },
      method: 'post',
      responseType: 'blob',
      headers: {
        meta: {
          skipErrorCatch: true,
          desc: '导出',
        },
      },
    });
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = window.URL.createObjectURL(new Blob([result.data]));
    let realFileName = '';
    if (fileName) {
      realFileName = fileName;
    } else if (result.headers['content-disposition']) {
      const matched = result.headers['content-disposition'].split(';').find((i) => i.includes('filename'));
      realFileName = matched ? matched.split('=')[1] : '数据导出.xlsx';
    } else {
      realFileName = '数据导出.xlsx';
    }

    link.setAttribute('download', realFileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
    Notice.destroy();
    Notice.success({
      title: '导出成功',
    });
  }
}

export default DataTransferUtil;
